/* 搜索框样式 */
.search-position {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.search-position::before {
  display: inline-block;
  content: '';
  width: 0;
  height:100%;
  vertical-align: middle;
}

#search-box {
  display: inline-block;
  vertical-align: middle;
  margin-top: -25%;
  background-color: inherit;
}

.search-position .logo {
  width:50%;
  max-width: 33.5rem;
  margin-bottom: 1rem;
}

.search-list-box{
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 0.5rem;
  padding: 0 15px;
  background-color: inherit;
}

#search-box .search-root {
  padding: 2px 4px;
  display: flex;
  text-align: center;
  box-shadow: 0 1px 4px rgba(0,0,0,0.1);
  /* box-shadow: 0 1px 3px 0 rgba(111,111,111,0.16), 0 0 0 1px rgba(111,111,111,0.08) !important; */
}

#search-box .search-root:hover {
  box-shadow: 0 1px 6px rgba(0,0,0,0.1);
}

#search-box .iyou_search {
  margin-left: 5px;
  flex: 1;
}

#search-box .iconButton {
  padding: 10px;
}

/* 搜索引擎 */
#search-engine-type {
  position: relative;
  text-align: center;
  border-radius:3px;
  z-index: 99;
  vertical-align: middle;
}

#search-engine-type button {
  display: inline-block;
  width: 5.2rem;
}

#search-engine-type::before {
  display: inline-block;
  content: '';
  width: 0;
  height:100%;
  vertical-align: middle;
}

#search-engine-type .icon {
  height: 1.7rem;
  width: 1.7rem;
}

#engine-list {
  position: absolute;
  width: 100%;
  right: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
}
#engine-list li {
  list-style: none;
  padding: 6px 12px 0 12px !important;
  justify-content: center;
  min-height: 2.5rem !important;
}

/* 搜索列表 */
#search-list{
  position: relative;
  padding-top: 5px;
  border-radius: 3px;
  /* background-color: #fff; */
}
#search-list li{
  cursor: pointer;
}

#search-li {
  color: #fff;
  background: #2098D1;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}
@media (max-width: 768px) {

}
@media (max-width: 576px) {
  .search-position .logo {
    width: 66%;
  }
  #search-box {
    margin-top: -75%;
  }
  #search-list li{
    padding-top: 5px;
    padding-bottom: 5px;
  }
  #search-list li .MuiListItemText-root{
    margin: 0;
  }
  #iyou_search,#search-list li .MuiTypography-body2{
    font-size: 14px;
  }
  #search-list li .MuiTypography-body2{
    font-weight: bold;
  }
}