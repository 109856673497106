#favorite-list {
  position: absolute;
  margin-top: 1.8rem;
  left: 0;
  text-align: left;
}

.favorite-list {
  opacity: 0.9;
  overflow: hidden;
  padding: 3px 6px !important;
  justify-content: unset !important;
}

#favorite-list .favorite-icon {
  padding-top: 7px;
}

#favorite-list .favorite-icon .icon {
  position: relative;
  width: 2.8rem;
  height:2.8rem;
  margin-right: 7px;
  padding: 3px;
  border-radius: 50%;
  border: 1px solid #ddd;
}


#favorite-list  .favorite-icon-title {
  font-weight: 600;
  width: calc(100% - 46px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .favorite-list {
    width: 24%;
    margin: 0 1.333% 1rem 0 !important;
    box-shadow: 1px 2px 7px rgba(0,0,0,0.1);
  }
  .favorite-list:nth-child(4n){
    margin-right: 0   !important;
  }
}

/* TODO: min-width 不小于*/
@media (min-width: 1200px) {
  .favorite-list {
    width: 15.666667%;
    margin: 0 1.2% 1rem 0 !important;
    box-shadow: 1px 2px 7px rgba(0,0,0,0.1);
  }
  .favorite-list:nth-child(6n){
    margin-right: 0   !important;
  }
}


@media (max-width: 992px) {
  #favorite-list .MuiButton-label,#favorite-list .favorite-icon {
    width:100%;
    display: block;
    text-align: center;
  }
  #favorite-list .favorite-icon .icon {
    width: 3.8rem;
    height: 3.8rem;
    margin-right: 0;
  }
  #favorite-list  .favorite-icon-title {
    width: 100%;
  }
}