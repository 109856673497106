/* 导航菜单 */
#menu {
  position: absolute;
  /* 左右居中 */
  left: 0;
  right: 0;
  bottom: 0;
  width: 66.44rem;
  max-height: 836px;
  /* height: calc(100vh - 150px); */
  border-radius: 5px;
  z-index: 1;
  box-shadow: 0px 4px 15px -2px rgba(0,0,0,0.2), 1px -5px 10px 1px rgba(0,0,0,0.14), 0px 0px 16px 0px rgba(0,0,0,0.12);
  /* 0 2px 8px rgba(0,0,0,0.15); */
}

#menu .row{
  margin: 0 !important;
  text-align: left;
}
.menu-list,.menu-title {
  justify-content: left !important;
}
.menu-list {
  position: relative;
  text-align: center;
  display: inline-block !important;
}
.menu-title {
  font-size: 1.1rem !important;
  font-weight: bold !important;
  padding-left: 1.4rem !important;
}

#menu .icon {
  width:  3.5rem;
  height: 3.5rem;
  margin: 1rem 0 0.5rem 0;
}

#menu .icon-favorite {
  position: absolute;
  top:   1px;
  right: -2px;
  padding: 13px;
  border-radius: 50%;
}

#menu .icon-favorite .icon {
  position: absolute;
  width:  1.5rem;
  height: 1.5rem;
  top:   2px;
  right: 4px;
  padding: 4px;
  margin: 0;
  border-radius: 50%;
  -webkit-appearance:none;
  background-color: #ccc;
  box-shadow: 1px 2px 5px #777;
}
#menu .icon-favorite .icon:hover {
  background-color: #bbb;
  box-shadow: 2px 2px 8px #666;
}

/* #menu .icon-favorite:hover .icon {
  width:  2.2rem;
  height: 2.2rem;
} */

#menu .addFavorite {
  position: absolute;
  bottom:   -4px;
  right:    3px;
}
#menu .addFavorite .icon {
  width:  2.5rem;
  height: 2.5rem;
  margin: 0;
  cursor: pointer;
}

.menu-icon, .menu-icon-title {
  display: inline-block !important;
  width: 100%;
}

#bottom-nav {
  position: fixed;
  bottom: 0rem;
  left:0;
  width: 100%;
  z-index: 100;
  text-align: center;
  margin-bottom: 1rem;
  transform: translateZ(0);
}
#bottom-nav .MuiBottomNavigation-root {
  margin: auto;
  width: 60%;
}

#bottom-nav .MuiBottomNavigation-root .icon {
  width: 2rem;
  height: 2rem;
}
#bottom-nav  .MuiBottomNavigationAction-root{
  /* max-width: 128px; */
  min-width: 66px;
}
#bottom-nav .MuiBottomNavigationAction-label {
  font-size: 10px !important;
}


@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}
@media (max-width: 768px) {
  #menu {
    width: 44.3rem;
    max-height: unset;
  }
}
@media (max-width: 576px) {
  #menu {
    width: auto;
    max-height: unset;
    /* height: calc(100vh - 115px); */
  }
  /* .safari-menu {
    height: calc(100vh - 220px) !important;
  } */
  #menu .col-12 {
    max-width: 95% !important;
  }
  #bottom-nav {
    width: 90%;
    left: 4.95%;
  }
  #bottom-nav .MuiBottomNavigation-root {
    width:100%;
  }
}