html, body {
  height:100%;
  margin: 0;
  overflow: hidden;
}
body{ -webkit-tap-highlight-color:rgba(0,0,0,0); }
span:focus{
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  -webkit-user-modify:read-write-plaintext-only;
}
#app {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.icon {
  width: 2em;
  height: 2em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.first-mount-hidden {
  visibility: hidden;
}

/* 滚动条 */
.FreeScrollbar-vertical-handler  {
  background-color: rgba(0, 0, 0, 0.5) !important;
  /* width: 30%  !important; */
}

.FreeScrollbar-vertical-track {
  border-left: none!important;
  width: 3px !important;
}

.FreeScrollbar-container {
  right: -18px !important;
  bottom: -21px !important;
}
/* 最总将样式集合起来 */