/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
html {
  font-size:14px;
}

/* #root {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
} */

@media (max-width: 576px) {
  html {
    font-size: 12px !important;
  }
}